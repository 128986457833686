import { LoggerModule } from "@vlinder-web/logger-module-react";
import _ from "lodash";
import { VApplication } from "../application";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { toast } from "react-hot-toast";
import { LOGIN_TOS } from "../pages/Login/graphql/login-tos.mutation";
import { getErrorMessage } from "../utils/error.message";

const SERVICE_NAME = "logintos.service";
const log: any = LoggerModule.getInstance();

const getGraphQLModule = () => {
    const app = VApplication.getInstance();
    return app.getSync("services.GraphqlServerModule") as GraphqlServerModule;
};

export class LoginTOSService {
    name = SERVICE_NAME;
    private static instance: LoginTOSService;

    private constructor() {
        this.report();
    }
    static getInstance() {
        if (!this.instance) {
            this.instance = new LoginTOSService();
        }
        return this.instance;
    }

    async getTos() {
        const graphqlModule = getGraphQLModule();
        const client = graphqlModule.getClient("graphql-gateway");
        try {
            if (client) {
                const res = await client.mutate({
                    mutation: LOGIN_TOS,
                    variables: {
                        privacyInput: {
                            type: "user_registration",
                            agreementUrl: "https://vlinder.io/privacy",
                        },
                        termsInput: {
                            type: "user_registration",
                            agreementUrl: "https://vlinder.io/terms",
                        },
                    },
                    fetchPolicy: "no-cache",
                });
                return res?.data?.acceptTAndC;
            }
        } catch (err: any) {
            getErrorMessage(err);
        }
    }

    private report() {
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
        log?.info(SERVICE_NAME, "Module Report");
        log?.info(SERVICE_NAME, "⦿ Name: " + this.name);
        log?.info(SERVICE_NAME, `⚡⚡⚡===================⚡⚡⚡`);
    }
}
